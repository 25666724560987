import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import ClientOnly from '../components/ClientOnly';
import * as Sentry from '@sentry/gatsby';
import FormOverlay from '../components/FormOverlay';
import GlobalStyles from '../components/GlobalStyles';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  token: '',
  tokenVerified: false,
  loading: true,
  loadingProgress: null,
  preventNavigation: false,
  preventNavigationPopup: {
    active: false,
    destination: '/',
  },
  user: {},
  alert: {
    active: false,
    content: '',
  },
  formOverlay: {
    users: [],
    hidden: false,
    active: false,
    header: '',
    customer: null,
    formData: null,
    formType: null,
    handleSubmit: () => {},
  },
};

Sentry.init({
  dsn: 'https://8b7c7350494f91237cf8306d02a7da56@o4506835364413440.ingest.us.sentry.io/4506916507615232',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.GATSBY_IS_DEVELOPMENT ? 'development' : 'production',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'https://h2o-teknik.netlify.app',
    'https://admin.h2oteknik.se',
    'https://dev-h2o.bucketmedia.se',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function reducer(state, action) {
  switch (action.type) {
    case 'TOKEN_VERIFIED':
      return {
        ...state,
        token: action.token,
        user: action.user,
        loading: false,
        tokenVerified: true,
      };
    case 'TOKEN_DISCARDED':
      localStorage.removeItem('token');
      return {
        ...state,
        loading: false,
        tokenVerified: false,
        user: {},
        token: '',
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.value,
        loadingProgress: !action.value ? null : state.loadingProgress,
      };
    case 'LOADING_PROGRESS': {
      return {
        ...state,
        loading: action.value,
        loadingProgress: action.progress,
      };
    }
    case 'ALERT':
      return {
        ...state,
        alert: {
          active: true,
          content: action.content,
        },
      };
    case 'CLOSE_ALERT':
      return {
        ...state,
        alert: {
          ...state.alert,
          active: false,
        },
      };
    case 'SET_PREVENT_NAVIGATE':
      return {
        ...state,
        preventNavigation: action.value,
      };
    case 'SET_PREVENT_NAVIGATE_POPUP':
      return {
        ...state,
        preventNavigationPopup: action.value,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return {
        ...state,
        token: '',
        tokenVerified: false,
        loading: false,
        user: {},
      };
    case 'SET_FORM_OVERLAY':
      return {
        ...state,
        formOverlay: action.data,
      };
    case 'HIDE_FORM_OVERLAY':
      return {
        ...state,
        formOverlay: {
          ...state.formOverlay,
          hidden: true,
        },
      };
    case 'SHOW_FORM_OVERLAY':
      return {
        ...state,
        formOverlay: {
          ...state.formOverlay,
          hidden: false,
        },
      };
    case 'CLOSE_FORM_OVERLAY':
      return {
        ...state,
        formOverlay: {
          users: [],
          active: false,
          hidden: true,
          header: '',
          customer: null,
          formData: null,
          formType: null,
          handleSubmit: () => {},
        },
      };
    default:
      return state;
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const checkToken = () => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      axios
        .post(
          process.env.GATSBY_BACKEND_API_URL + 'users/auth/verify-token',
          {},
          {
            headers: { Authorization: 'Bearer ' + localToken },
          }
        )
        .then((res) => {
          dispatch({
            type: 'TOKEN_VERIFIED',
            token: localToken,
            user: res.data.user,
          });
          if (
            window.location.pathname === '/' ||
            window.location.pathname === ''
          )
            navigate('/notifications');
        })
        .catch(() => {
          dispatch({ type: 'TOKEN_DISCARDED' });
          navigate('/');
        });
    } else {
      navigate('/');
      dispatch({ type: 'LOADING', value: false });
    }
  };

  useEffect(() => {
    if (!state.tokenVerified) checkToken();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tokenVerified]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <GlobalStyles>
          <ClientOnly>{children}</ClientOnly>
          {state.formOverlay.active && <FormOverlay />}
        </GlobalStyles>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
