export const reservoirFormDefault = {
  basicInformation: {
    name: '',
    protected: false,
    gps: {
      lat: '',
      lon: '',
    },
    adress: [''],
    type: '',
    implementation: '',
    heightOverGround: '',
    innerHeight: '',
    volume: '',
    volume2: '',
    volume3: '',
    volume4: '',
    area: '',
    area2: '',
    area3: '',
    area4: '',
    compartments: {
      quantity: '',
      name: '',
    },
    innerMaterial: '',
    innerMaterialOther: '',
    feeding: '',
    feedingOther: '',
    electricity: {
      hasElectricity: false,
      type: '',
    },
    water: {
      hasWater: false,
      waterOut: false,
      waterPost: false,
      waterTank: false,
    },
    summerStatusNew: '-',
    isWinterWork: false,
  },
  establishment: {
    crane: false,
    elevator: false,
    telferCrane: false,
    innerTelfer: false,
    parkingReservoirDistance: '',
    establishmentTime: '',
    establishmentTimeManual: '',
    reEstablishmentRequired: false,
    pumpPitUnderLadder: '',
  },
  hatch: {
    manlucka: {
      hasManlucka: false,
      brand: '',
      brandOther: '',
    },
    safetyHatch: {
      hasSafetyHatch: false,
      dateInstalled: '',
    },
    measurments: {
      w: '',
      h: '',
    },
  },
  ventilation: {
    hasVentilation: false,
    type: '',
    typeOther: '',
    h2oVentDate: '',
    quantity: '',
  },
  cleaning: {
    robotPossible: false,
    hasDiagonalPipe: false,
    diagonalPipe: 'Ej valt ännu',
    canPushRobotOverPipe: false,
    canDriveAroundPipe: false,
    canDriveUnderPipe: false,
    noRobotReason: '',
    robotType: '',
    vr50reason: '',
    cleaningTime: '',
    cleaningTimeManual: '',
    tripodSpace: '',
    floater: '',
  },
};

export const workorderFormDefault = {
  orderPerson: '',
  orderPersons: [],
  status: 'Utkast',
  additionalTechnicians: [],
  sentTo: [],
  technicians: [],
  concreteEngineer: '-',
  startDateTime: '',
  startDateTimeNew: '',
  meetingSpot: [''],
  table: [],
  other: [''],
};

export const workorderServices = [
  'Inspektion under drift',
  'Kontroll under drift',
  'Inspektion tömd reservoar',
  'Kontroll tömd reservoar',
  'Robotrengöring VR-50',
  'Robotrengöring VR-600',
  'Manuell rengöring',
  'Statusbedömning',
  'Tillståndsbedömning betong',
  'Montage säkerhetslucka',
  'Montage ventilation',
  'Montage kranarm',
  'Inmätning manlucka',
  'Inmätning ventilation',
  'Övrigt',
  'Montage tätningslist',
  'Montage avfuktare',
  'Sanering svartmögel',
  'Byte pollenfilter',
];

export const workPlanFormDefault = {
  orderPerson: '',
  status: 'Utkast',
  sentTo: [],
  technicians: [],
  startDate: '',
  endDate: '',
  creationDate: '',
  reservoir: '',
  riskOverview: [],
  equipment: [],
  workMomentDescriptions: [],
};

export const adminUserDefault = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  passwordRepeat: '',
  role: '',
};

export const adminEditUserDefault = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  passwordRepeat: '',
  role: '',
  _id: '',
  active: false,
};
